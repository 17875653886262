import { Button, Flex, Heading } from '@chakra-ui/react'
import type { ActionArgs, LoaderArgs } from '@remix-run/node'
import { redirect } from '@remix-run/node'
import type { ComponentProps } from 'react'

import type { SocialsProvider } from 'remix-auth-socials'
import { Form } from '~/components/Form'
import { authenticator, getUserIfLoggedIn } from '~/services/auth/auth.server'
import { MicrosoftLogo } from '../../lib/svg/MicrosoftLogo'

type SocialButtonProps = {
    provider: keyof typeof SocialsProvider
    label: string
} & ComponentProps<typeof Button>

const SocialButton: React.FC<SocialButtonProps> = ({ provider, label, ...props }) => (
    <Form action={`/auth/microsoft`} method="post">
        <Button type="submit" {...props}>
            {label}
        </Button>
    </Form>
)

export const loader = async ({ request }: LoaderArgs) => {
    const user = await getUserIfLoggedIn(request)
    if (user) return redirect('/')
    return null
}

export const action = async ({ request }: ActionArgs) => {
    return await authenticator.authenticate('user-pass', request, {
        successRedirect: '/',
        failureRedirect: '/login',
    })
}

export default function Login() {
    return (
        <Flex flexDir="column" justifyContent="center" alignItems="center" w="full" h="full" gap={8} pt={200}>
            <Flex direction="row" gap={8}>
                <Flex direction="column" w={450}>
                    <Heading size="md">Primary Health Operational and Commissioning Unified System</Heading>
                    <Heading>Welcome to PHOCUS</Heading>

                    <SocialButton
                        leftIcon={<MicrosoftLogo />}
                        mt={4}
                        provider="MICROSOFT"
                        label="Login with Microsoft"
                    />
                </Flex>
            </Flex>
        </Flex>
    )
}
